import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useUploadImageToMLManual } from 'hooks/useUploadImageToML';
import { useImageValidation as imageValidator, VALID_IMAGE_FORMAT } from 'hooks/useImageValidation';

interface ProductImage {
  _id: string;
  name?: string;
  size?: number;
  src?: string;
  width?: number;
  height?: number;
  link?: string;
  variant?: string;
}

interface UploadedImageVariation {
  url: string;
  size: string;
  secure_url: string;
}

interface UploadedImage {
  id: string;
  variations: Array<UploadedImageVariation>;
}

interface ImageSelectorProps {
  onImageListChanged: (images: ProductImage[]) => void;
  productImages: ProductImage[];
  onProductImagesChanged: (images: ProductImage[]) => void;
}

export const ImageSelector: React.FC<ImageSelectorProps> = ({
  onImageListChanged,
  productImages,
  onProductImagesChanged,
}) => {
  const [allImages, setAllImages] = useState<ProductImage[]>(productImages || []);
  const [draggingOver, setDraggingOver] = useState<boolean>(false);

  const { uploadImageToML, loading, error, data: newImageData } = useUploadImageToMLManual();

  useEffect(() => {
    onImageListChanged(allImages.filter((img) => !img.link)); // Uploaded images without a link need to be uploaded
  }, [allImages]);

  useEffect(() => {
    if (newImageData) {
      const closestVariation = newImageData.variations.reduce((prev: UploadedImageVariation, current: UploadedImageVariation) => {
        return Math.abs(prev.size.split('x').map(Number).reduce((a, b) => a * b) - 75 * 75) < Math.abs(current.size.split('x').map(Number).reduce((a, b) => a * b) - 75 * 75) ? prev : current;
      });

      const uploadedImage: ProductImage = {
        _id: newImageData.id,
        link: closestVariation.secure_url,
        variant: closestVariation.size,
        width: parseInt(closestVariation.size.split('x')[0]),
        height: parseInt(closestVariation.size.split('x')[1]),
      };
      setAllImages([...allImages, uploadedImage]);
      onProductImagesChanged([...allImages, uploadedImage]);
    }
  }, [newImageData]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryStr = reader.result as string;
        uploadImageToML({
          variables: {
            base64: binaryStr,
          },
        });
        const image = new Image();
        image.onload = () => {
          const productImage: ProductImage = {
            _id: `${Date.now()}_${file.name}`,
            name: file.name,
            size: file.size,
            src: binaryStr,
            width: image.width,
            height: image.height,
          };
        };
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
    },
    multiple: false,
  });

  const handleDelete = (imageId: string) => {
    const imgToDeleteId = imageId.split('_')?.[1]?.split('_')?.[0];
    const remainingImages = allImages.filter((img) => {
      const imgId = img?.link?.split('_')?.[1]?.split('_')?.[0];
      return imgId !== imgToDeleteId;
    });
    setAllImages(remainingImages);
    onProductImagesChanged(remainingImages);
  };

  const thumbnails = allImages.filter((img) => (img.width === 75 && img.height === 75) || img._id || img.link?.includes('-S.jpg'));

  return (
    <div className='w-80 pl-4'>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          className='bg-gray-500 w-72 h-32 flex items-center justify-center text-center text-white rounded-md cursor-pointer'
          onDragEnter={() => setDraggingOver(true)}
          onDragLeave={() => setDraggingOver(false)}
          onDragEnd={() => setDraggingOver(false)}
          onDropCapture={() => setDraggingOver(false)}
          style={{ border: draggingOver ? '2px dashed white' : 'none' }}
        >
          Arrastra una imagen o presiona para agregar
        </div>
      </div>
      <div className='flex flex-wrap gap-1 justify-start mt-2'>
        {thumbnails.map((image) => (
          <div key={`${image.link}-${image.width}-${image.height}`} className='relative'>
            <img alt='' src={image.link || image.src} className='max-w-full h-auto' />
            <div
              className='absolute top-0 right-0 text-sm p-1 bg-white opacity-50 font-bold cursor-pointer'
              onClick={() => handleDelete(image.link || '')}
            >
              X
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
