import React, { useEffect, useState } from 'react';
import AddManualProductForm from './AddManualProductForm';
import {
  AddCatalogProductResultError,
  AddCatalogProductResultLoading,
  AddCatalogProductResultSuccess,
} from '../common/AddCatalogProductResult';
import { PRODUCT_FORM_STATES } from '../types/AddCatalogProduct.types';

export interface AmazonImage {
  variant: string; // Por ejemplo, "MAIN"
  link: string;
  width: number;
  height: number;
}

export interface ProductImage {
  id?: string;
  link: string;
  width: number;
  height: number;
}

export interface MercadoLibreImage {
  id?: string;
  variant: string; // Por ejemplo, "500x500"
  link: string;
}

export function transformAmazonImage(image: AmazonImage): ProductImage {
  return {
    id: undefined,
    link: image.link,
    width: image.width,
    height: image.height,
  };
}

export function transformMercadoLibreImage(image: MercadoLibreImage): ProductImage {
  const [widthStr, heightStr] = image.variant.split('x');
  const width = parseInt(widthStr, 10);
  const height = parseInt(heightStr, 10);

  return {
    id: image.id,
    link: image.link,
    width: isNaN(width) ? 0 : width,
    height: isNaN(height) ? 0 : height,
  };
}

function AddManualProduct({
  visible,
  onCancel,
  onPublish,
  closeButtonLabel = 'Enviar link',
  identifier = '',
  titleAsinProduct = '',
  productDescriptionAsin = '',
  amazonImages = [],
  mlImages = [],
  productImages = [],
  productName = '',
  categoryPath = [],
  attributeValues = [],
  price = 0,
  currency = 'UYU',
  formTitle = 'Publicar nuevo producto',
  buttonName = 'Publicar',
  onSubmit,
  currentFormState = PRODUCT_FORM_STATES.SHOW_SUCCESS,
  publishProductResponse = [],
  showCreationProductResult = false,
  loadingFetch = false,
  soldQuantity = 0,
  itemId = '',
  stock = 12,
  catalog_listing = false,
  permalink = '',
  catalog_product_id = '',
}: {
  visible: boolean;
  onCancel: () => void;
  onPublish: (permalink: string) => void;
  closeButtonLabel?: string;
  identifier?: string;
  titleAsinProduct: string;
  productDescriptionAsin: string;
  amazonImages: AmazonImage[];
  mlImages: MercadoLibreImage[];
  productImages: ProductImage[];
  productName?: string;
  categoryPath?: string[];
  attributeValues?: { name: string; value: string | number }[];
  price?: number;
  currency?: string;
  formTitle?: string;
  buttonName?: string;
  onSubmit: (payload: any) => void;
  currentFormState?: string;
  publishProductResponse?: any;
  showCreationProductResult?: boolean;
  loadingFetch?: boolean;
  soldQuantity?: number;
  itemId?: string;
  stock?: number;
  catalog_listing?: boolean;
  permalink?: string;
  catalog_product_id?: string;
}) {
  const initialImages = [
    ...productImages,
    ...amazonImages.map(transformAmazonImage),
    ...mlImages.map(transformMercadoLibreImage)
  ];
  const [selectedImages, setSelectedImages] = useState<ProductImage[]>(initialImages);

  useEffect(() => {
    const newImages = initialImages.filter(
      (img) => img.width >= 500 && img.height >= 500
    );
    setSelectedImages(newImages);
  }, [productImages]);

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  return !visible ? (
    <></>
  ) : (
    <div className='sm:p-3'>
      <div className='fixed top-0 left-0 lg:w-screen lg:h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center backdrop-blur-sm'>
        <div className='w-screen flex justify-center max-h-screen overflow-y-auto'>
          {
            <div className='max-w-screen-xl w-full flex justify-center items-center rounded-lg'>
              <div className='w-[660px] h-[98%] bg-white shadow-md rounded-lg px-8 pt-8 pb-8'>
                {currentFormState == PRODUCT_FORM_STATES.SUBMIT_LOADING && (
                  <AddCatalogProductResultLoading />
                )}
                {currentFormState == PRODUCT_FORM_STATES.SUBMIT_ERROR && (
                  <AddCatalogProductResultError />
                )}
                {currentFormState == PRODUCT_FORM_STATES.SUBMIT_SUCCESS && (
                  <AddCatalogProductResultSuccess
                    handleCloseOkSuccess={() => {
                      onPublish(publishProductResponse.publishManualProduct.id);
                    }}
                    addCatalogProductInfo={String(showCreationProductResult)}
                    closeButtonLabel={closeButtonLabel}
                  />
                )}
                {currentFormState == PRODUCT_FORM_STATES.SHOW_SUCCESS && (
                  <AddManualProductForm
                    identifier={identifier}
                    handleCancel={handleCancel}
                    productName={productName}
                    price={price ?? 0}
                    currency={currency}
                    titleAsinProduct={
                      titleAsinProduct?.replace(
                        /[^a-zA-Z0-9 ñÑáéíóúÁÉÍÓÚüÜ,.]/g,
                        ''
                      ) ?? ''
                    }
                    productDescriptionAsin={productDescriptionAsin}
                    productImages={initialImages}
                    categoryPath={categoryPath ?? []}
                    attributeValues={attributeValues}
                    formTitle={formTitle}
                    buttonName={buttonName}
                    onSubmit={onSubmit}
                    loadingFetch={loadingFetch}
                    soldQuantity={soldQuantity}
                    itemId={itemId}
                    stock={stock ?? 0}
                    catalog_listing={catalog_listing}
                    permalink={permalink}
                    catalog_product_id={catalog_product_id}
                  />
                )}
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export { AddManualProduct };
